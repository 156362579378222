import { Chip as MUIChip } from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "./chip.module.scss";

const MuiChip = styled(MUIChip, {
  shouldForwardProp: (prop) => prop !== "className" && prop !== "chipSize", // Exclude chipSize from being forwarded
})(({ theme, status, style, icon, chipSize }) => ({
  ...style,
  backgroundColor:
    {
      success: "#4fa986",
      primary: "#1776f2",
      error: "#f45047",
      warning: "#ffaa01",
    }[status] || "#a1aeb7",
  color: "#ffffff",
  fontWeight: 500,
  ...(icon &&
    !icon.props.children && {
      width: chipSize || 25,
      height: chipSize || 25,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: chipSize ? chipSize / 3 : "9px", // Adjust padding based on size
      position: "relative",
    }),
}));

const Chip = ({ className, chipSize, ...rest }) => {
  return <MuiChip {...rest} chipSize={chipSize} className={`${styles.chip} ${className || ""}`} />;
};

export default Chip;
