import React from "react";
import styles from "./stations-filter.module.scss";
import { Autocomplete, Field, Filter, Button } from "components/commons";
import { locale } from "localization/en";
import { SearchField } from "components/commons";
import StationType from "enums/station-type";
import StationSubscriptionEnum from "enums/station-subscription";
import StationStatusEnum from "enums/station-status";

const StationsFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  resetFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  actionText,
  actionOnClick,
}) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <div>
          <Filter
            close={resetFilter}
            clear={applyClearFilter}
            submit={() => {
              applyFilter();
            }}
            filterCount={filterCount}
          >
            {/* <Field
              className={styles.date}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
              label={locale.dateOnboarded}
            >
              <DateRange
                value={{
                  startDate: filterState.startDate,
                  endDate: filterState.endDate,
                }}
                onChange={(_, { value }) => {
                  const { startDate, endDate } = value;
                  modifyFilter({ startDate, endDate });
                }}
              />
            </Field> */}
            <Field
              label={locale.stationType}
              labelPosition={"left"}
              className={styles.fieldSelect}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                {...filterState.type}
                hasAll
                multiple
                options={[
                  {
                    label: StationType.DODOX,
                    value: StationType.DODOX,
                  },
                  {
                    label: StationType.WIDOX,
                    value: StationType.WIDOX,
                  },
                  {
                    label: StationType.UWIDOX,
                    value: StationType.UWIDOX,
                  },
                  {
                    label: StationType.COCO,
                    value: StationType.COCO,
                  },
                  {
                    label: StationType.CODOX,
                    value: StationType.CODOX,
                  },
                  {
                    label: StationType.COXO,
                    value: StationType.COXO,
                  },
                  {
                    label: StationType.WILCO,
                    value: StationType.WILCO,
                  },
                  {
                    label: StationType.WIBCO,
                    value: StationType.WIBCO,
                  },
                  {
                    label: StationType.CODO,
                    value: StationType.CODO,
                  },
                  {
                    label: StationType.DODO,
                    value: StationType.DODO,
                  },
                  {
                    label: StationType.XDODO,
                    value: StationType.XDODO,
                  },
                  {
                    label: StationType.XDODO_W,
                    value: StationType.XDODO_W,
                  },
                  {
                    label: StationType.XWIDO,
                    value: StationType.XWIDO,
                  },
                  {
                    label: StationType.XWIDO_W,
                    value: StationType.XWIDO_W,
                  },
                  {
                    label: StationType.UDODOX,
                    value: StationType.UDODOX,
                  },
                  {
                    label: StationType.CODOL,
                    value: StationType.CODOL,
                  },
                ]}
                onChange={(name, { value, isSelectedAll }) => {
                  modifyFilter({ [name]: { value, isSelectedAll, name } });
                }}
              />
            </Field>
            <Field
              label={locale.subscriptionType}
              labelPosition={"left"}
              className={styles.fieldSelect}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                {...filterState.subscription}
                hasAll
                multiple
                options={[
                  {
                    label: "IMS",
                    value: StationSubscriptionEnum.IMS,
                  },
                  {
                    label: "POS",
                    value: StationSubscriptionEnum.POS,
                  },
                ]}
                onChange={(name, { value, isSelectedAll }) =>
                  modifyFilter({ [name]: { value, isSelectedAll, name } })
                }
              />
            </Field>
            <Field
              label={locale.status}
              labelPosition={"left"}
              className={styles.fieldSelect}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                {...filterState.status}
                hasAll
                multiple
                options={[
                  {
                    label: locale.pending,
                    value: StationStatusEnum.PENDING,
                  },
                  {
                    label: locale.active,
                    value: StationStatusEnum.ACTIVE,
                  },
                  {
                    label: locale.deactivated,
                    value: StationStatusEnum.DEACTIVATED,
                  },
                  {
                    label: locale.manualMode,
                    value: StationStatusEnum.MANUAL_MODE,
                  },
                ]}
                onChange={(name, { value, isSelectedAll }) =>
                  modifyFilter({ [name]: { value, isSelectedAll, name } })
                }
              />
            </Field>
            <Field
              label={locale.connectedToPTS}
              labelPosition={"left"}
              className={styles.fieldSelect}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                {...filterState.isConnectedToPts}
                hasAll
                multiple
                options={[
                  {
                    label: locale.online,
                    value: "online",
                  },
                  {
                    label: locale.offline,
                    value: "offline",
                  },
                ]}
                onChange={(name, { value, isSelectedAll }) =>
                  modifyFilter({ [name]: { value, isSelectedAll, name } })
                }
              />
            </Field>
            <Field
              label={locale.connectedToPump}
              labelPosition={"left"}
              className={styles.fieldSelect}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                {...filterState.isConnectedToPump}
                hasAll
                multiple
                options={[
                  {
                    label: locale.online,
                    value: "online",
                  },
                  {
                    label: locale.offline,
                    value: "offline",
                  },
                ]}
                onChange={(name, { value, isSelectedAll }) =>
                  modifyFilter({ [name]: { value, isSelectedAll, name } })
                }
              />
            </Field>
          </Filter>
          <Field className={styles.search}>
            <SearchField
              placeholder={[locale.stationName, locale.stationCode].join(", ")}
              value={searchKey}
              searchWithSymbols={false}
              restrict={false}
              onChange={(_, { value }) => {
                modifySearch(value);
              }}
              onEnter={applySearch}
              onClear={applyClearSearch}
            />
          </Field>
        </div>
        {actionText && (
          <div className={styles.primaryButtonContainer}>
            <Button primary onClick={actionOnClick}>
              {actionText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default StationsFilter;
