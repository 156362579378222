import locale from "localization/en";

export const stationsColumn = [
  { key: "code", text: locale.stationCode },
  { key: "name", text: locale.stationName },
  { key: "type", text: locale.stationType },
  { key: "subscription", text: locale.subscriptionType },
  { key: "status", text: `${locale.status} / ${locale.dateTime}` },
  { key: "lastChecked", text: locale.lastChecked },
  { key: "lastTransaction", text: locale.lastTransaction },
  { key: "connectedToPTS", text: locale.connectedToPTS },
  { key: "connectedToPump", text: locale.connectedToPump },
  { key: "actions", text: locale.actions },
];
