import StationStatusEnum from "enums/station-status";
import StationType from "enums/station-type";

export const initialFilterState = () => {
  return {
    startDate: null,
    endDate: null,
    searchKey: "",
    order: "desc",
    type: {
      name: "type",
      isSelectedAll: true,
      value: Object.values(StationType),
    },
    subscription: {
      name: "subscription",
      isSelectedAll: true,
      value: ["ims", "pos"],
    },
    status: {
      name: "status",
      isSelectedAll: true,
      value: Object.values(StationStatusEnum),
    },
    isConnectedToPts: {
      name: "isConnectedToPts",
      isSelectedAll: true,
      value: ["online", "offline"],
    },
    isConnectedToPump: {
      name: "isConnectedToPump",
      isSelectedAll: true,
      value: ["online", "offline"],
    },
    perPage: 10,
  };
};
