const env = {
  ID: "local",
  API_URL: "https://api.locq.com",
  // WEB_SOCKET_URL: "wss://5dxtm8b2gj.execute-api.ap-southeast-1.amazonaws.com/dev",
  WEB_SOCKET_URL: "wss://5jhgzsdcke.execute-api.ap-southeast-1.amazonaws.com/staging",
  GOOGLE_MAP_API_KEY: "AIzaSyAIPhiJk9EgZDcwgrfD4n-kATpZzWKVt7w",
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyBo1iyktLtgWMccr74_QKAtJ0qbXBcCeRI",
    authDomain: "stocq-prod.firebaseapp.com",
    databaseURL: "https://stocq-prod-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "stocq-prod",
    storageBucket: "stocq-prod.firebasestorage.app",
    messagingSenderId: "341915993148",
    appId: "1:341915993148:android:ad50957dc66dc06a8eef39",
  },
  APP_FIREBASE_CONFIG: {
    apiKey: "AIzaSyAgbfBt_Lz0C8EwjxrNI9J-vtfPib6BvHw",
    authDomain: "pivot-seaoil-ap-test.firebaseapp.com",
    databaseURL: "https://pivot-seaoil-ap-test.firebaseio.com",
    projectId: "pivot-seaoil-ap-test",
    storageBucket: "pivot-seaoil-ap-test.appspot.com",
    messagingSenderId: "836556693696",
    appId: "1:836556693696:web:0e82436e0ffdea48720d25",
    measurementId: "G-QVN8590QCX",
    name: "mobile",
  },
};

export default env;
