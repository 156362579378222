import CustomAccordion from "components/commons/accordion/accordion";
import StationDetailsComponent from "./sections/station-details-station";
import locale from "localization";
import { useRouter } from "hooks";
import { FormMode } from "enums";
import PosDetailsComponent from "./sections/station-details-pos-details";
import UgtsComponent from "./sections/station-details-ugts";
import UsersComponent from "./sections/station-details-users";
import DryStocksComponent from "./sections/station-details-dry-stock";
import PromosComponent from "./sections/station-details-promos";
import { useFormContext } from "contexts/form-context";
import { Button } from "components/commons";
import { useContext, useEffect } from "react";
import useProducts from "hooks/useProducts";
import { RenderElementByFormMode } from "./station-details.module";
import { CircularProgress } from "@material-ui/core";
import { AppContext } from "contexts";
import usePromos from "hooks/usePromos";

const AddEditStationForm = ({
  dryStocks,
  users,
  stationDetails,
  isSubmitting,
  handleOnSubmit,
  loading,
  promos,
}) => {
  const { isFormSubmittable, modifyField, fields, getFormValues, setInitialState } =
    useFormContext();
  const { apisState } = useContext(AppContext);
  const { allProducts } = useProducts();
  const { allPromos } = usePromos();
  const router = useRouter();
  const pageMode = router.query?.mode || FormMode.Add;

  useEffect(() => {
    if (dryStocks?.length > 0 && allProducts.length > 0) {
      const initialDataDryStocks = dryStocks
        .map(({ sku, amount, ...rest }) => {
          const product = allProducts.find(({ sku: productSku }) => productSku === sku);
          if (product) {
            return {
              ...product,
              sku,
              overridePrice: String(amount),
            };
          }
          return null;
        })
        .filter((product) => !!product);

      modifyField("dryStocks", {
        value: initialDataDryStocks,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dryStocks, allProducts, pageMode]);

  useEffect(() => {
    if (users?.length > 0) {
      const initialDataUsers = users.map(
        ({ firstName, lastName, userId, role, username, name }) => ({
          username,
          // firstName: firstName,
          // lastName: lastName,
          name: firstName + " " + lastName,
          userId,
          position: role,
        })
      );
      modifyField("users", {
        value: initialDataUsers,
      });
      setInitialState((init) => ({
        ...init,
        users: {
          ...init.users,
          value: initialDataUsers,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, pageMode]);

  useEffect(() => {
    if (promos?.length) {
      modifyField("promos", {
        value: promos,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promos, allPromos, pageMode]);

  return (
    <>
      <CustomAccordion
        label={locale.stationDetails}
        defaultExpanded={true}
        children={<StationDetailsComponent pageMode={pageMode} />}
      />

      <CustomAccordion
        label={locale.posDetails}
        defaultExpanded={true}
        children={<PosDetailsComponent pageMode={pageMode} />}
      />

      <CustomAccordion
        label={locale.ugts}
        defaultExpanded={true}
        children={<UgtsComponent pageMode={pageMode} />}
        subLabel={
          apisState?.["station-details"]?.loading ? (
            <CircularProgress size={15} style={{ marginTop: "0.5em" }} />
          ) : (
            `${fields?.ugts?.value?.length} ${locale.ugts}`
          )
        }
        subLabelStyle={{
          color: fields?.ugts?.value?.length > 0 ? "#5349b7" : "#a6aab3",
        }}
      />
      <CustomAccordion
        label={locale.users}
        defaultExpanded={true}
        children={<UsersComponent pageMode={pageMode} />}
        subLabel={
          apisState?.["station-users"]?.loading ? (
            <CircularProgress size={15} style={{ marginTop: "0.5em" }} />
          ) : (
            `${fields?.users?.value?.length} ${locale.users}`
          )
        }
        subLabelStyle={{
          color: fields?.users?.value?.length > 0 ? "#5349b7" : "#a6aab3",
        }}
      />
      {/* <CustomAccordion
        label={locale.keyAccount}
        defaultExpanded={true}
        children={<KeyAccountsComponent pageMode={pageMode} />}
      /> */}
      <CustomAccordion
        label={locale.dryStockProducts}
        defaultExpanded={true}
        children={<DryStocksComponent pageMode={pageMode} />}
        subLabel={
          apisState?.["station-dry-stocks"]?.loading ? (
            <CircularProgress size={15} style={{ marginTop: "0.5em" }} />
          ) : (
            `${fields?.dryStocks?.value?.length} ${locale.products}`
          )
        }
        subLabelStyle={{
          color: fields?.dryStocks?.value?.length > 0 ? "#5349b7" : "#a6aab3",
        }}
      />
      <CustomAccordion
        label={locale.promos}
        defaultExpanded={true}
        children={<PromosComponent pageMode={pageMode} />}
        subLabel={
          apisState?.["station-promos"]?.loading || allPromos.length === 0 ? (
            <CircularProgress size={15} style={{ marginTop: "0.5em" }} />
          ) : (
            `${fields?.promos?.value?.length} ${locale.promos}`
          )
        }
        subLabelStyle={{
          color: fields?.promos?.value?.length > 0 ? "#5349b7" : "#a6aab3",
        }}
      />
      <br />
      <br />
      <RenderElementByFormMode mode={FormMode.Add}>
        <Button
          color="primary"
          type="submit"
          onClick={() => handleOnSubmit?.(getFormValues())}
          disabled={!isFormSubmittable || loading}
          loading={loading}
        >
          {locale.continue}
        </Button>
      </RenderElementByFormMode>
    </>
  );
};

export default AddEditStationForm;
