import {
  CloudQueueOutlined,
  CloudOffOutlined,
  FullscreenOutlined,
  RotateRightOutlined,
} from "@material-ui/icons";
import { Chip, Text } from "components/commons";
import locale from "localization/en";
import styles from "./stations-tabs.module.scss";
import { useCallback, useContext, useEffect, useState } from "react";
import useStations from "hooks/useStations";
import { useMount } from "hooks";
import StationStatusEnum from "enums/station-status";
import { Skeleton } from "@mui/material";
import { AppContext } from "contexts";
const StationTabs = ({ onChange }) => {
  const { fetch, allStations } = useStations();
  const { apisState } = useContext(AppContext);

  const getValue = useCallback(
    (status, customStations) => {
      customStations = customStations || allStations;
      let stations = [];

      if (status === StationStatusEnum.ACTIVE) {
        stations = customStations.filter((station) => station?.isConnectedToPts);
      } else if (status === "offline") {
        stations = customStations.filter((station) => !station?.isConnectedToPts);
      } else if (status === StationStatusEnum.MANUAL_MODE) {
        stations = customStations.filter(
          (station) => station?.isManualMode || station?.status === StationStatusEnum.MANUAL_MODE
        );
      } else {
        stations = customStations.filter((station) => station?.status === status);
      }

      return stations.length;
    },
    [allStations]
  );

  const [tabs, setTabs] = useState([
    {
      active: false,
      label: locale.online,
      status: StationStatusEnum.ACTIVE,
      value: getValue(StationStatusEnum.ACTIVE),
      max: allStations.length,
      icon: (
        <Chip
          icon={<CloudQueueOutlined fontSize="medium" htmlColor="white" />}
          status={"success"}
          size="medium"
          chipSize={37}
          style={{
            backgroundColor: "#8fdb85",
          }}
        />
      ),
    },
    {
      active: false,
      label: locale.offline,
      status: "offline",
      value: getValue("offline"),
      max: allStations.length,
      icon: (
        <Chip
          icon={<CloudOffOutlined fontSize="medium" htmlColor="white" />}
          status={"error"}
          size="medium"
          chipSize={37}
        />
      ),
    },
    {
      active: false,
      label: locale.manualMode,
      status: StationStatusEnum.MANUAL_MODE,
      value: getValue(StationStatusEnum.MANUAL_MODE),
      max: allStations.length,
      icon: (
        <Chip
          icon={<FullscreenOutlined fontSize="medium" htmlColor="white" />}
          status={"warning"}
          size="medium"
          chipSize={37}
        />
      ),
    },
    {
      active: false,
      label: locale.pending,
      status: StationStatusEnum.PENDING,
      value: getValue(StationStatusEnum.PENDING),
      max: allStations.length,
      icon: (
        <Chip
          icon={<RotateRightOutlined fontSize="large" htmlColor="white" />}
          status={"primary"}
          size="medium"
          chipSize={37}
        />
      ),
    },
    {
      active: false,
      label: locale.deactivated,
      status: StationStatusEnum.DEACTIVATED,
      value: getValue(StationStatusEnum.DEACTIVATED),
      max: allStations.length,
      icon: (
        <Chip
          icon={<CloudOffOutlined fontSize="medium" htmlColor="white" />}
          status={"default"}
          size="medium"
          chipSize={37}
        />
      ),
    },
  ]);

  const handleOnClick = (tab) => {
    if (tab.active) {
      onChange?.(null);
    } else {
      onChange?.(tab);
    }

    setTabs((tabs) => {
      return tabs.map((t) => ({
        ...t,
        active: tab.active ? false : t.label === tab.label,
      }));
    });
  };

  useMount(() => {
    fetch();
  });

  useEffect(() => {
    setTabs((tabs) => {
      return tabs.map((t) => ({
        ...t,
        value: getValue(t.status, allStations),
        max: allStations.length,
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStations]);

  return (
    <div
      className={styles.tabs}
      style={{
        gridTemplateColumns: tabs.map(() => "1fr").join(" "),
      }}
    >
      {tabs.map((tab) => (
        <div
          key={tab.label}
          className={tab.active ? styles.active : ""}
          tabIndex={1}
          onClick={() => handleOnClick(tab)}
        >
          {apisState?.["all-stations"]?.loading ? (
            <Skeleton height={80} />
          ) : (
            <>
              <Text strong color="#a6aab3">
                {tab.label}
              </Text>
              <div className={styles.body}>
                <div>
                  <div>{tab.value}</div> <Text subtitle>/{tab.max}</Text>
                </div>
                <div>{tab.icon}</div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default StationTabs;
