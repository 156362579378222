import { DateTime } from "enums";
import moment from "moment";

export const dateFormat = (date) => {
  if (date === "" || date === null || date === undefined) {
    return "";
  }
  return moment(date).format("DD MMM yyyy");
};

export const timeFormat = (time, includeSeconds = false) => {
  if (time === "" || time === null || time === undefined) {
    return "";
  }

  return moment(time).format(`hh:mm${includeSeconds ? ":ss" : ""} a`);
};

export const dateTimeFormat = (dateTime) => {
  if (dateTime === "" || dateTime === null || dateTime === undefined) {
    return "-";
  }
  return moment(dateTime).format("DD MMM yyyy, hh:mm A");
};

export const formatDate = (date, format = DateTime.A) => {
  return moment(date).format(format);
};

export const combineDateAndTimeInput = (date1, date2) => {
  const datePart = date1;
  const timePart = date2;
  if (datePart && timePart) {
    const result = new Date(
      datePart.getFullYear(),
      datePart.getMonth(),
      datePart.getDate(),
      timePart.getHours(),
      timePart.getMinutes(),
      timePart.getSeconds(),
      timePart.getMilliseconds()
    );
    return result;
  }
  return new Date();
};

export const timeStringToDate = (timeString) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const dateTime = `${currentDate} ${timeString}`;
  return moment(dateTime, "YYYY-MM-DD HH:mm:ss").toDate();
};
