import React from "react";
import styles from "../../station-details.module.scss";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";
import { promosColumn } from "components/modals/select-promo/select-promo-columns";
import { initialFilterState } from "components/modals/select-promo/select-promo-filter-state";
import {
  mapDataToList,
  mapFilterToRequest,
} from "components/modals/select-promo/select-promo-mapper";
import classNames from "classnames";
import { overrideDataTableColumns } from "utils/object.utils";

const ViewPromosComponent = ({ stationPromos, isView }) => {
  const cols = overrideDataTableColumns(
    promosColumn ? promosColumn.filter((col) => col.key !== "select") : [],
    [
      {
        key: "promoName",
        width: "30%",
      },
    ]
  );

  const { table } = useDataTable({
    value: stationPromos || [],
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "station-promos",
      mapper: (promo) => mapDataToList({ promo, disableAction: true }),
      columns: cols,
    },
  });

  return isView ? (
    <div className={classNames(styles.container, styles.viewPromosTable)}>
      <DataTableV2 {...table} tableVersion="v2" hideNoSearchResultRender hidePagination />
    </div>
  ) : (
    <div className={classNames(styles.container, styles.plainTable, styles.inputPromosTable)}>
      <DataTableV2 {...table} hideNoSearchResultRender hidePagination />
    </div>
  );
};

export default ViewPromosComponent;
