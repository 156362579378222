import { Autocomplete, Field, TextField } from "components/commons";
import locale from "localization/en";
import styles from "./station-status-modal.module.scss";
import ConfirmModal from "components/modals/confirm-modal/confirm-modal";
import Validation from "utils/validation.utils";
import { StationStatus } from "enums";
import { validateField } from "hooks/useForm";

const UpdateStationStatusModal = (modalState = {}) => {
  const { fields, modifyField, modifyForm } = modalState?.form;
  const { status } = modalState;

  return (
    <ConfirmModal
      {...modalState}
      className={styles.modal}
      titleStyle={styles.textNight}
      contentStyle={[styles.contentStyle]}
    >
      {status === StationStatus.DEACTIVATED && (
        <>
          <Field
            label={
              <>
                {locale.reason}
                <span style={{ color: "red", marginLeft: "5px" }}>*</span>
              </>
            }
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              options={[
                {
                  label: locale.endOfContract,
                  value: locale.endOfContract,
                },
                {
                  label: locale.stationClosed,
                  value: locale.stationClosed,
                },
                {
                  label: locale.others,
                  value: locale.others,
                },
              ]}
              {...fields?.reason}
              onChange={(name, { value }) => {
                let form = {
                  [name]: { value },
                };

                if (value === locale.others) {
                  form = {
                    ...form,
                    remarks: validateField(
                      {
                        ...fields.remarks,
                        disabled: false,
                        inputDisabled: false,
                        validations: [Validation.required()],
                        required: true,
                      },
                      fields
                    ),
                  };
                } else {
                  form = {
                    ...form,
                    remarks: {
                      ...fields.remarks,
                      value: "",
                      disabled: true,
                      inputDisabled: true,
                      validations: [],
                      required: false,
                    },
                  };
                }
                modifyForm(form);
              }}
            />
          </Field>
          <Field
            label={locale.remarks}
            childrenClassName={styles.fieldContent}
            labelPosition={"left"}
            className={styles.reasonRemarks}
          >
            <TextField
              multiline
              {...fields?.remarks}
              value={fields?.remarks?.value}
              onChange={modifyField}
            />
          </Field>
        </>
      )}
    </ConfirmModal>
  );
};

export default UpdateStationStatusModal;
