import React, { useContext, useState } from "react";
import locale from "localization/en";
import StationType from "enums/station-type";
import { InputAmount, QuantitySelector } from "components/field";
import styles from "../../station-details.module.scss";
import {
  Autocomplete,
  Checkbox,
  Field,
  Text,
  TextField,
  TimePickerRange,
} from "components/commons";
import { useFormContext } from "contexts/form-context";
import { AppContext } from "contexts";
import Validation from "utils/validation.utils";
import moment from "moment";
import { Box } from "@material-ui/core";
import {
  today1159PMTime,
  today12AMtime,
} from "modules/promos/promo-details/add-edit-promo-form-state";
import { RenderElementByFormMode, RenderInput } from "../../station-details.module";
import { FormMode } from "enums";
import { prettifyStationSubscription } from "utils/pretty.utils";
import { isLatLongFormat } from "utils";

const InputStationDetailsComponent = () => {
  const { fields, modifyField, modifyForm, formMode } = useFormContext();
  const isView = formMode === FormMode.View;

  const { apisState, setApisState } = useContext(AppContext);

  const apiErrorMessageStationCode =
    apisState?.["create-station"]?.errorData?.data?.errorCode === "S1001" &&
    apisState?.["create-station"]?.errorData?.data?.message;

  const [isSet24Hours, setIs24Hours] = useState(false);

  const subscriptionOption = [
    {
      label: "IMS",
      value: "ims",
    },
    {
      label: "POS",
      value: "pos",
    },
  ];

  const stationTypeOptions = [
    ...Object.values(StationType).map((value) => ({ label: value, value })),
  ];

  const handleOnChange24Hours = (name, { value }) => {
    if (value) {
      modifyForm({
        [fields.openingTime.name]: {
          value: today12AMtime(),
          disabled: true,
          error: false,
        },
        [fields.closingTime.name]: {
          value: today1159PMTime(),
          disabled: true,
          error: false,
        },
      });
    } else {
      modifyForm({
        [fields.openingTime.name]: {
          value: today12AMtime(),
          disabled: false,
          error: false,
        },
        [fields.closingTime.name]: {
          value: null,
          disabled: false,
          error: true,
        },
      });
    }
    setIs24Hours(value);
  };

  return (
    <div className={styles.container}>
      <Field
        label={locale.subscription}
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.fieldContent}
      >
        <RenderInput
          {...fields?.subscription}
          options={subscriptionOption}
          onChange={modifyField}
          prettify={prettifyStationSubscription}
          Component={Autocomplete}
        />
      </Field>
      <Field
        label={locale.stationCode}
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.fieldContent}
      >
        <RenderInput
          {...fields?.code}
          onChange={(name, { value }) => {
            setApisState((state) => ({ ...state, "create-station": {} }));
            modifyField(name, { value });
          }}
          helperText={apiErrorMessageStationCode}
          error={fields?.code?.error || !!apiErrorMessageStationCode}
          Component={TextField}
        />
      </Field>
      <RenderElementByFormMode>
        <Field
          label={locale.stationName}
          labelPosition={"left-aligned"}
          labelClassName={isView ? styles.labelCement : styles.label}
          childrenClassName={styles.longContent}
          message={!isView && fields?.name?.helperText}
        >
          <RenderInput
            {...fields?.name}
            helperText=""
            onChange={modifyField}
            Component={TextField}
          />
        </Field>
      </RenderElementByFormMode>
      <Field
        label={locale.stationType}
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.fieldContent}
      >
        <RenderInput
          {...fields?.type}
          // hasAll
          // multiple
          options={stationTypeOptions}
          onChange={modifyField}
          Component={Autocomplete}
        />
      </Field>
      <Field
        label={locale.birTaxpayerName}
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.longContent}
      >
        <RenderInput {...fields.taxPayerName} onChange={modifyField} Component={TextField} />
      </Field>
      <Field
        label={locale.birRegisteredAddress}
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.longContent}
        className={styles.multilineField}
      >
        <RenderInput
          {...fields?.taxPayerRegisteredAddress}
          onChange={modifyField}
          multiline
          Component={TextField}
        />
      </Field>
      <Field
        label={locale.birTin}
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.longContent}
      >
        <RenderInput
          {...fields.birTin}
          onChange={(name, { value }) => {
            // Regex pattern for "000-000-000-00000" format
            const regex = /^[a-zA-Z0-9-]+$/;

            if (!value || regex.test(value)) {
              modifyField(name, { value: value });
            }
          }}
          Component={TextField}
        />
      </Field>
      <Field
        label={
          <>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gridGap={formMode === FormMode.View ? "1.3em" : "2.3em"}
            >
              <Box>{locale.opening} </Box>
              <Box>{locale.closing}</Box>
            </Box>
          </>
        }
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.fieldContent}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <RenderInput
              style={{ width: "150px" }}
              orientation="vertical"
              minutesGap={30}
              startTimeProps={{
                ...fields.openingTime,
                variant: "purple",
                actions: { clear: true, cancel: true, ok: true },
                label: "Set Time",
                date: moment().toDate(),
                onChange: (name, { value, minTime }) => {
                  let endTimeValidations = [Validation.required()];
                  if (minTime) {
                    endTimeValidations.push(
                      Validation.minDate({
                        startDate: minTime,
                      })
                    );
                  }

                  modifyForm({
                    [name]: { value: value },
                    endTime: {
                      validations: endTimeValidations,
                    },
                  });
                },
              }}
              endTimeProps={{
                ...fields.closingTime,
                variant: "purple",
                actions: { clear: true, cancel: true, ok: true },
                label: "Set Time",
                date: moment().toDate(),
                onChange: (name, { value, error }) => {
                  modifyForm({
                    [name]: { value: value },
                  });
                },
              }}
              disableMinTime
              Component={TimePickerRange}
            />
          </Box>
          <Box>
            {formMode !== FormMode.View && (
              <Box display={"flex"} alignItems={"center"}>
                <Box>
                  <Checkbox value={isSet24Hours} onChange={handleOnChange24Hours} />
                </Box>
                <Box>
                  <Text subtitle>{locale.set24Hours}</Text>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Field>
      {/* <Field
        label={locale.opening}
        labelPosition={"left-aligned"}
        labelClassName={styles.label}
        childrenClassName={styles.shortContent}
      >
        <TimePickerV2
          {...fields.openingTime}
          onChange={(name, { value }) => {
            modifyForm({
              [name]: {
                value
              }
            })
          }}
        />
      </Field>
      <Field
        label={locale.closing}
        labelPosition={"left-aligned"}
        labelClassName={styles.label}
        childrenClassName={styles.shortContent}
      >
        <TimePickerV2
          {...fields.closingTime}
          onChange={(name, { value, }) => {
            modifyForm({
              [name]: {
                value
              }
            })
          }}
        />
      </Field> */}
      <Field
        label={locale.latitude}
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.longContent}
        message={!isView && fields?.lat?.helperText}
      >
        <RenderInput
          {...fields?.lat}
          onChange={(name, { value }) => {
            if (isLatLongFormat(value)) {
              modifyField(name, { value });
            }
          }}
          helperText=""
          Component={TextField}
        />
      </Field>
      <Field
        label={locale.longitude}
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.longContent}
        message={!isView && fields?.long?.helperText}
      >
        <RenderInput
          {...fields?.long}
          onChange={(name, { value }) => {
            if (isLatLongFormat(value)) {
              modifyField(name, { value });
            }
          }}
          helperText=""
          Component={TextField}
        />
      </Field>
      <Field
        label={locale.pumpBrands}
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.fieldContent}
      >
        <RenderInput
          {...fields?.pumpBrands}
          hasAll
          multiple
          options={[
            {
              label: "Tatsuno",
              value: "Tatsuno",
            },
            {
              label: "Tominaga",
              value: "Tominaga",
            },
          ]}
          onChange={modifyField}
          Component={Autocomplete}
        />
      </Field>
      <Field
        label={locale.safeDropThreshold}
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.longContent}
      >
        <RenderInput
          {...fields?.safeDropThreshold}
          onChange={modifyField}
          innerLabel
          isPriceValue
          Component={InputAmount}
        />
      </Field>
      <Field
        label={locale.maxTransactionStacking}
        labelPosition={"left-aligned"}
        labelClassName={isView ? styles.labelCement : styles.label}
        childrenClassName={styles.longContent}
      >
        <RenderInput
          {...fields.maxTransactionStacking}
          onChange={modifyField}
          Component={QuantitySelector}
        />
      </Field>
    </div>
  );
};

export default InputStationDetailsComponent;
