import React, { useEffect, useMemo } from "react";
import { FormMode, ProductCodeEnum, Products } from "enums";
import locale from "localization/en";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

import { InputAmount } from "components/field";
import styles from "../../station-details.module.scss";
import { prettifyProductCode } from "utils/pretty.utils";
import { Button, Autocomplete, Text, Product } from "components/commons";
import { useDynamicFieldsForm } from "hooks";
import Validation from "utils/validation.utils";
import { useFormContext } from "contexts/form-context";
import { RenderElementByFormMode, RenderInput } from "../../station-details.module";
import ViewUgtsComponent from "../view-components/view-ugts";

const InputUgtsComponent = ({ ugts, setUgts }) => {
  const {
    fields,
    modifyField,
    fieldsAsRows,
    addRow,
    addRows,
    removeRow,
    isFormSubmittable,
    getRowsValues,
    getFormValues,
  } = useDynamicFieldsForm({ rowId: "product" });

  const rootForm = useFormContext();
  const { initialState, setInitialState } = rootForm;

  useEffect(() => {
    rootForm.modifyField("ugts", { value: getRowsValues() });
    rootForm.setSubFormsSubmittableState((subForms) => ({
      ...subForms,
      ugts: isFormSubmittable,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, getRowsValues, isFormSubmittable]);

  useEffect(() => {
    const ugts = initialState?.ugts?.value || [];
    if (ugts?.length > 0) {
      const rows = ugts.map(({ product, liters }) => {
        return [
          {
            name: "product",
            value: product,
            required: true,
            validations: [Validation.required()],
          },
          {
            name: "liters",
            value: liters,
            required: true,
            decimalPlaces: 4,
            isPriceValue: true,
            innerLabel: true,
            prefix: "L",
            max: 50000,
            placeholder: "0.000",
            validations: [Validation.required(), Validation.min(0.1)],
          },
        ];
      });
      addRows(rows);
      setInitialState((init) => ({
        ...init,
        ugts: {
          ...init.ugts,
          value: [],
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState?.ugts]);

  const handleAddRow = () => {
    addRow([
      {
        name: "product",
        value: "",
        required: true,
        validations: [Validation.required()],
      },
      {
        name: "liters",
        value: "",
        required: true,
        decimalPlaces: 4,
        isPriceValue: true,
        innerLabel: true,
        prefix: "L",
        max: 50000,
        placeholder: "0.000",
        validations: [Validation.required(), Validation.min(0.1)],
      },
    ]);
  };

  const handleRemove = (rowIndex) => {
    removeRow(rowIndex);
  };

  const productsOption = useMemo(() => {
    const option = [
      { label: prettifyProductCode(Products.Diesel), value: Products.Diesel },
      { label: prettifyProductCode(Products.Gas91), value: Products.Gas91 },
      { label: prettifyProductCode(Products.Gas95), value: Products.Gas95 },
      { label: prettifyProductCode(Products.Gas97), value: Products.Gas97 },
    ];

    // avoid duplicate entry
    const selected = Object.values(getFormValues());
    return option.filter(({ value }) => !selected.includes(value));
  }, [getFormValues]);

  return (
    <div className={styles.container}>
      <RenderElementByFormMode mode={FormMode.View}>
        <ViewUgtsComponent ugts={getRowsValues()} />
      </RenderElementByFormMode>
      <RenderElementByFormMode>
        {fieldsAsRows.length >= 1 && (
          <div style={{ marginLeft: "150px" }}>
            <div className={styles.row}>
              <Text className={styles.labelGray}>{locale.product}</Text>
              <Text className={styles.labelGray}>{locale.liters}</Text>
            </div>
          </div>
        )}
        {fieldsAsRows.map((item, i) => {
          const sku = fields?.[item.product.name].value;
          return (
            <div className={styles.row} key={item.product.name}>
              <Text className={styles.midLabel}>{`${locale.ugt} ${i + 1}`}</Text>
              <div className={styles.fieldContent}>
                <RenderInput
                  {...fields?.[item.product.name]}
                  options={[
                    ...productsOption,
                    {
                      label: prettifyProductCode(sku),
                      value: sku,
                    },
                  ]}
                  startAdornment={
                    sku && (
                      <Product
                        grass={sku === ProductCodeEnum.GAS91}
                        salsa={sku === ProductCodeEnum.GAS95}
                        deepBlue={sku === ProductCodeEnum.GAS97}
                        cheddar={sku === ProductCodeEnum.DIESEL}
                        style={{ width: "20px" }}
                      />
                    )
                  }
                  placeholder={"Select One"}
                  onChange={modifyField}
                  prettify={prettifyProductCode}
                  Component={Autocomplete}
                />
              </div>
              <div className={styles.spacing} />
              <div className={styles.fieldContent}>
                <RenderInput
                  {...fields?.[item.liters.name]}
                  onChange={modifyField}
                  Component={InputAmount}
                />
              </div>
              <div className={styles.spacing} />
              {i > 0 && (
                <RenderElementByFormMode>
                  <div className={styles.circleIcon} onClick={() => handleRemove(i)}>
                    <CloseIcon style={{ fontSize: "14px" }} />
                  </div>
                </RenderElementByFormMode>
              )}
            </div>
          );
        })}
        <br />
        <Button
          variant="outlined"
          className={styles.button}
          startIcon={<AddIcon />}
          onClick={handleAddRow}
          disabled={!isFormSubmittable || productsOption.length === 0}
        >
          {locale.addUgt}
        </Button>
        <br />
      </RenderElementByFormMode>
    </div>
  );
};

export default InputUgtsComponent;
